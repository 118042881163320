import React, { Component, useState } from "react";

import { NavLink } from "react-router-dom";
import "./navbar.css";
import logo from "./image/Artboard 1@00x.png";

import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/outline";

const IThomePage = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "IT Services",
      link0: "IT AMC",
      redirection0: "/itamc",
      link1: "IT Relocation",
      redirection1: "/itrelocation",
      link2: "IT Infrastructure",
      redirection2: "/itinfrastructure",
      link3: "IT Outsourcing",
      redirection3: "/itoutsourcing",
      link4: "IT Consultancy",
      redirection4: "/itconsultancy",
      link5: "Cloud Migration",
      redirection5: "/cloudmigration",
      // link6: "IT Support",
      // redirection6: "itsupport",
      link6: "ICT Solutions",
      redirection6: "/ictsolutions",
      link7: "New Office IT Setup",
      redirection7: "/newofficeitsetup",
    },
    {
      question: "Outsourcing",
      link0: "IT Outsourcing",
      redirection0: "/itoutsourcing",
      link1: "BPO Outsourcing",
      redirection1: "/bpooutsourcing",
    },
    {
      question: "IT Infrastructure",
      // link0: "New Office IT Setup",
      // redirection0: "newofficeitsetup",
      link0: "VPN Solutions",
      redirection0: "/vpnsolutions",
      link1: "Data Backup & Disaster Recovery Services",
      redirection1: "/databackupdisasterrecoveryservices",
      link2: "Firewall Installation",
      redirection2: "/firewallinstallation",
      link3: "Server Virtualization Services",
      redirection3: "/servervirtualizationservices",
      link4: "Office 365 Services",
      redirection4: "/office365services",
      link5: "IT Hardware Services",
      redirection5: "/ithardwareservices",
      link6: "Office Telephone System",
      redirection6: "/officetelephonesystem",
      link7: "Structure Cabling",
      redirection7: "/structurecabling",
    },
    {
      question: "ELV & Security",
      // link0: "Structure Cabling",
      // redirection0: "structurecabling",
      link0: "CCTV Installation Dubai",
      redirection0: "/cctvinstallationdubai",
      link1: "Access Control & Time Attendance",
      redirection1: "/accesscontroltimeattendance",
      link2: "Telephone Solutions",
      redirection2: "/telephonesolutions",
      link3: "SIRA Approved CCTV Installation",
      redirection3: "/siraapprovedcctvinstallation",
    },
  ];

  const toggleAnswer = (index) => {
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  return (
    <>
      <div className="max-w-4xl mx-auto px-6 py-8">
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div key={index} className="pb-4">
              <div
                className="flex justify-between items-center cursor-pointer text-lg font-medium text-gray-700"
                onClick={() => toggleAnswer(index)}
              >
                <span
                  className="font-semibold
              "
                >
                  {faq.question}
                </span>
                {openIndex === index ? (
                  <ChevronUpIcon className="w-6 h-6 text-blue-500" />
                ) : (
                  <ChevronDownIcon className="w-6 h-6 text-gray-600" />
                )}
              </div>
              {openIndex === index && (
                <div className="mt-2 text-gray-700">
                  <NavLink className=" font-semibold" to={faq.redirection0}>
                    {faq.link0}
                  </NavLink>
                  <br className="" />
                  <NavLink className=" font-semibold" to={faq.redirection1}>
                    {faq.link1}
                  </NavLink>
                  <br className="" />
                  <NavLink className=" font-semibold" to={faq.redirection2}>
                    {faq.link2}
                  </NavLink>
                  <br className="" />
                  <NavLink className=" font-semibold" to={faq.redirection3}>
                    {faq.link3}
                  </NavLink>
                  <br className="" />
                  <NavLink className=" font-semibold" to={faq.redirection4}>
                    {faq.link4}
                  </NavLink>
                  <br className="" />
                  <NavLink className=" font-semibold" to={faq.redirection5}>
                    {faq.link5}
                  </NavLink>
                  <br className="" />
                  <NavLink className=" font-semibold" to={faq.redirection6}>
                    {faq.link6}
                  </NavLink>
                  <br className="" />
                  <NavLink className=" font-semibold" to={faq.redirection7}>
                    {faq.link7}
                  </NavLink>
                  <br className="" />
                  <NavLink className=" font-semibold" to={faq.redirection8}>
                    {faq.link8}
                  </NavLink>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

class Navbar extends Component {
  // const [openIndex, setOpenIndex] = useState(null);

  state = { clicked: false };
  handleclick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  // state = { clicked: false, dropdownOpen: false };

  handleNavClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  handleDropdownToggle = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  render() {
    return (
      <>
        <nav>
          <a href="/">
            <img src={logo} className="logo" alt="logo" />
          </a>
          <div className="pb-4">
            <ul
              id="navbar"
              className={this.state.clicked ? "#navbar active1" : "#navbar"}
            >
              <div className="backdrop"></div>
              <li>
                <NavLink className="link" to="/">
                  HOME
                </NavLink>
              </li>
              <li className="relative group">
                {/* Services Button */}
                <button
                  type="button"
                  className="link inline-flex items-center gap-x-1 text-gray-700"
                  onClick={this.handleDropdownToggle}
                >
                  SERVICES
                  <svg
                    className="w-5 h-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.22 8.22a.75.75 0 011.06 0L10 11.94l3.72-3.72a.75.75 0 111.06 1.06l-4.25 4.25a.75.75 0 01-1.06 0L5.22 9.28a.75.75 0 010-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>

                {/* Dropdown Menu */}
                {this.state.dropdownOpen && (
                  <div class="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
                    <div class="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm/6 shadow-lg ring-1 ring-gray-900/5">
                      <IThomePage />
                    </div>
                  </div>
                )}
              </li>

              <li>
                <NavLink className="link" to="/about">
                  ABOUT US
                </NavLink>
              </li>
              <li>
                <NavLink className="link" to="/contact">
                  CONTACT US
                </NavLink>
              </li>
            </ul>
          </div>
          <div id="mobile" onClick={this.handleclick}>
            <i
              id="bar"
              className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}
            ></i>
          </div>
        </nav>
      </>
    );
  }
}
export default Navbar;
